import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  FormControl,
  FormGroup,
  Col,
  ControlLabel,
  Radio,
  HelpBlock,
  Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';

class NewProjectModal extends React.Component {
  state = {
    name: '',
    type: 'poc',
    isSaving: false
  };

  projectCreateFormRef = React.createRef();

  render() {
    return (
      <Modal backdrop="static" show={this.props.isOpen} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="form-horizontal"
            ref={this.projectCreateFormRef}
            method="post"
            action={this.props.urlTemplates.get('createProject')}
          >
            <FormGroup>
              <Col componentClass={ControlLabel} sm={4}>
                Name
              </Col>
              <Col sm={8}>
                <FormControl
                  autoFocus
                  name="name"
                  placeholder="My Project"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
              </Col>
            </FormGroup>
            {this.renderTypes()}
            <FormControl
              type="hidden"
              name="organizationId"
              value={this.props.selectedOrganizationId}
            />
            <FormControl type="hidden" name="xsrf" value={this.props.xsrf} />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            block
            bsStyle="primary"
            className="btn-confirm"
            disabled={this.state.isSaving || !this.state.name}
            onClick={this.handleCreate}
          >
            <FontAwesomeIcon
              icon={this.state.isSaving ? 'spinner' : 'check-circle'}
              spin={this.state.isSaving}
            />{' '}
            Create Project
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderTypes() {
    return (
      <>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Type
          </Col>
          <Col sm={8}>
            <FormControl.Static>Project can be upgraded anytime later.</FormControl.Static>
          </Col>
        </FormGroup>
        {this.props.projectTemplates.map((template) => {
          const templateId = template.get('stringId', template.get('id'));

          return (
            <FormGroup key={templateId}>
              <Col sm={8} smOffset={4}>
                <Radio
                  name="type"
                  checked={templateId === this.state.type}
                  value={templateId}
                  onChange={this.handleTypeChange}
                >
                  {template.get('name')}
                </Radio>
                <HelpBlock>
                  {template.get('description')}
                  {template.get('billedMonthlyPrice') && (
                    <>
                      <br />
                      {`$${numeral(template.get('billedMonthlyPrice')).format('0,0')} / month`}
                    </>
                  )}
                </HelpBlock>
              </Col>
            </FormGroup>
          );
        })}
      </>
    );
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handleTypeChange = (e) => {
    this.setState({ type: e.target.value });
  };

  handleCreate = () => {
    this.setState({ isSaving: true });
    this.projectCreateFormRef.current.submit();
  };
}

NewProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  xsrf: PropTypes.string.isRequired,
  selectedOrganizationId: PropTypes.number.isRequired,
  urlTemplates: PropTypes.object.isRequired,
  projectTemplates: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired
};

export default NewProjectModal;
